@import "node_modules/react-modal-video/scss/modal-video.scss";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
:root {
  --clr-text-dark: rgba(20, 20, 20, 0.9);
  --clr-primary: #0788d9;
  --clr-secondary: #ff2b2a;
  --clr-primary-light: #7eb2ff;
  --clr-bg-dark: rgb(26, 26, 26);
  --fixed-width: 80%;
}
body {
  font-size: 16px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--clr-text-dark);
    margin: 0.5rem 0;
  }

  p,
  span {
    color: rgba(0, 0, 0, 0.7);
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }
  :root {
    --fixed-width: 90%;
  }
}
